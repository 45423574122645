import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const AGBPage = () => {
  return (
    <Layout isSinglePage={true}>
      <SEO
        keywords={[`webwerker`, `webdevelopment`, `app-development`, `angular`]}
        title="Impressum"
      />
      <section id="imprint">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2>Impressum</h2>

          <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
          <p>
            Tobias Brandenberg | webwerker (Einzelunternehmer)
            <br />
            c/o IP-Management #8608
            <br />
            Ludwig-Erhard-Straße 18
            <br />
            20459 Hamburg
          </p>

          <h4>Kontakt</h4>
          <p>
            Telefon: +49 (0) 271 - 319 28 567
            <br />
            Telefax: +49 (0) 271 - 319 29 139
            <br />
            E-Mail: hallo@webwerker.io
          </p>

          <h4>Redaktionell verantwortlich</h4>
          <p>
            Tobias Brandenberg | webwerker (Einzelunternehmer)
            <br />
            c/o IP-Management #8608
            <br />
            Ludwig-Erhard-Straße 18
            <br />
            20459 Hamburg
          </p>

          <h3>EU-Streitschlichtung</h3>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Meine E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h3>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h3>
          <p>
            Ich bin nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
            vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>

          <p>
            Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default AGBPage;
